
<template>
  <div class="loadPage">
    <img src="@/assets/loading_logo.png">
    <a-spin size="large" />
    <div>慧聪行业资源网</div>
  </div>
</template>
<script>
export default {
  name: "LoadPage",
};
</script>
<style lang="less" scoped>
.loadPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100vw;
  height: 100vh;
  background-color: #f5f5f5;
  img{
    width: 90px;
    height: 90px;
  }
  .ant-spin{
    margin: 30px 0 20px;
  }
  >div:last-child{
    font-size: 30px;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="rightNav">
    <div class="zhiCustomBtn" id="dynBtn">
      <span class="iconfont icon-zaixiankefu"></span>
      <div>在线<br>客服</div>
    </div>
    <div>
      <span class="iconfont icon-qiwei"></span>
      <div>企微<br>咨询</div>
      <div class="popover">
        <img src="@/assets/wq_popover.png">
      </div>
    </div>
    <div>
      <span class="iconfont icon-wechathao"></span>
      <div>关注</div>
      <div class="popover">
        <img src="@/assets/xcx_popover.png">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "rightNav",
  props: {},
  data: function () {
    return {

    };
  },
  mounted: function () {
  
  },
}
</script>
<style lang="less" scoped>
@media screen and (min-width: 1025px) {
  .rightNav {
  position: fixed;
  right:0;
  top:calc(50% - 135px);
  z-index:15;
  >div,>a{
    width: 60px;
    height: 90px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: pre-line;
    color:#fff;
    font-size:12px;
    cursor: pointer;
    position:relative;
    span{
      width:60px;
      height:45px;
      font-size:22px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    >div:nth-child(2){
      width:60px;
      height:45px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .popover{
      position: absolute;
      right:60px;
      top:calc(50% - 75px);
      opacity: 0;
      height: 0;
      transition:opacity .3s linear;
      img{
        height:0;
        width:0;
      }
    }
  }
  >div>div:hover,>div>span:hover{
    ~.popover{
      height: auto;
      opacity: 1;
      img{
        height:150px;
        width:auto;
      }
    }
  }
  >div:first-child{
    background:#EC1327;
    border-top-left-radius: 12px;
  }
  >div:nth-child(2){
    background:#FF8D00;
  }
  >div:last-child{
    background:#FF682F;
    border-bottom-left-radius: 12px;
  }
}
}
@media screen and (max-width: 1024px) {
  .rightNav{
    display: none;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"homeFooter"},[_c('div',{staticClass:"topBox"},[_c('router-link',{attrs:{"to":"/easyMerchants"}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_yizhaotong.png")}})]),_c('div',[_c('span',[_vm._v("易招通")]),_c('span',[_vm._v("一切为招商而来")])])]),_c('router-link',{attrs:{"to":"/tiktok"}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_tt.png")}})]),_c('div',[_c('span',[_vm._v("抖音竞价")]),_c('span',[_vm._v("多渠道全网营销")])])]),_c('router-link',{attrs:{"to":"/shopAbout"}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_clouds.png")}})]),_c('div',[_c('span',[_vm._v("云商铺")]),_c('span',[_vm._v("B2B线上货柜")])])]),_c('router-link',{attrs:{"to":"/newsList?id=642179999336855"}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_xunzhan.png")}})]),_c('div',[_c('span',[_vm._v("行业巡展")]),_c('span',[_vm._v("买卖双方面对面交流")])])]),_c('router-link',{attrs:{"to":"/newsList?id=654485439158098"}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_shenghui.png")}})]),_c('div',[_c('span',[_vm._v("品牌盛会")]),_c('span',[_vm._v("打造企业品牌形象")])])]),_c('router-link',{attrs:{"to":"/superConditions"}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_shanq.png")}})]),_c('div',[_c('span',[_vm._v("超级商情")]),_c('span',[_vm._v("为企业开疆拓土")])])])],1),_c('div',{staticClass:"middleBox"},[_c('div',[_vm._m(0),_c('div',{staticClass:"urlItem"},[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("关于我们")]),_c('div',{staticClass:"zhiCustomBtn"},[_vm._v("联系我们")])],1),_vm._m(1)]),_vm._m(2),_vm._m(3)]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"urlItem"},[_c('div',[_vm._v("法律声明")]),_c('div',[_vm._v("网站地图")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("友情链接")]),_c('div',{staticClass:"urlBox"},[_c('div',{staticClass:"urlItem"},[_c('a',{attrs:{"href":"https://www.cm.hczyw.com/","target":"_blank"}},[_vm._v("慧聪工程机械网")]),_c('a',{attrs:{"href":"https://www.edu.hczyw.com/","target":"_blank"}},[_vm._v("慧聪教育网")]),_c('a',{attrs:{"href":"https://www.water.hczyw.com/","target":"_blank"}},[_vm._v("慧聪水工业网")]),_c('a',{attrs:{"href":"https://www.dj.hczyw.com/","target":"_blank"}},[_vm._v("商业展示")])]),_c('div',{staticClass:"urlItem"},[_c('a',{attrs:{"href":"https://www.audio.hczyw.com/","target":"_blank"}},[_vm._v("慧聪音响灯光网")]),_c('a',{attrs:{"href":"https://www.fire.hczyw.com/","target":"_blank"}},[_vm._v("慧聪消防网")]),_c('a',{attrs:{"href":"https://www.secu.hczyw.com/","target":"_blank"}},[_vm._v("慧聪物联网")]),_c('a',{attrs:{"href":"http://www.im.hczyw.com/","target":"_blank"}},[_vm._v("智造网")])]),_c('div',{staticClass:"urlItem"},[_c('a',{attrs:{"href":"https://www.ledp.hczyw.com/","target":"_blank"}},[_vm._v("慧聪LED屏网")]),_c('a',{attrs:{"href":"https://www.qipei.hczyw.com/","target":"_blank"}},[_vm._v("慧聪汽服网")]),_c('a',{attrs:{"href":"https://www.hotel.hczyw.com/","target":"_blank"}},[_vm._v("慧聪酒店网")])]),_c('div',{staticClass:"urlItem"},[_c('a',{attrs:{"href":"https://www.hvacr.hczyw.com/","target":"_blank"}},[_vm._v("慧聪暖通与舒适家居网")]),_c('a',{attrs:{"href":"https://www.homea.hczyw.com/","target":"_blank"}},[_vm._v("慧聪家电网")]),_c('a',{attrs:{"href":"https://www.ibuychem.com/","target":"_blank"}},[_vm._v("买化塑")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"QRBox"},[_c('div',[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/QR.png")}})]),_c('div',[_vm._v("资源网移动端")])]),_c('div',[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/gzh.jpg")}})]),_c('div',[_vm._v("资源网公众号")])]),_c('div',[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/xcx.jpg")}})]),_c('div',[_vm._v("资源网小程序")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottomBox"},[_c('div',[_c('div',[_vm._v("广州慧聪叁陆零网络科技有限公司")]),_c('div',[_vm._v("慧聪行业资源网www.hczyw.com "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("粤ICP备2021157007号")]),_vm._v(" 粤公网安备44010402003188号")])]),_c('div',[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_360.png")}})]),_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_xing.png")}})]),_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_quanguo.png")}})]),_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_anquanshu.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobileFooter"},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_logo.png")}})]),_c('div',[_vm._v("广州慧聪叁陆零网络科技有限公司")]),_c('div',[_vm._v("慧聪行业资源网www.hczyw.com")]),_c('div',[_c('img',{staticClass:"police",attrs:{"src":require("@/assets/police.png")}}),_vm._v("公安备案号：粤公网安备44010402003188号")]),_c('div',[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_360.png")}})]),_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_xing.png")}})]),_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_quanguo.png")}})]),_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("@/assets/ZY_yewei_anquanshu.png")}})])])])
}]

export { render, staticRenderFns }
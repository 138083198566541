import Vue from "vue";
import Vuex from "vuex";
import { isLoggedIn } from '@/utils/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: isLoggedIn(),
    baseUrl: process.env.VUE_APP_BASEURL
  },
  getters: {},
  mutations: {
    login(state) {
      state.isLogin = true;
    },
    logout(state) {
      state.isLogin = false;
    }
  },
  actions: {
    login({ commit }) {
      commit('login');
    },
    logout({ commit }) {
      commit('logout');
    }
  },
  modules: {},
});

import { decrypt, encrypt } from './encryption';

export function getUserInfo() {
  const userCookie = document.cookie.split('; ').find(row => row.startsWith('hczyw_user='));
  if (userCookie) {
    const encryptedUser = userCookie.split('=')[1];
    const decryptedUser = decrypt(decodeURIComponent(encryptedUser));
    return decryptedUser ? JSON.parse(decryptedUser) : null;
  }
  return null;
}

export function getToken() {
  const tokenCookie = document.cookie.split('; ').find(row => row.startsWith('hczyw_token='));
  return tokenCookie ? tokenCookie.split('=')[1] : null;
}

export function setUserInfo(user) {
  console.log(user);
  const userData = {
    id: user.id,
    nickname: user.nickname,
    phone: user.phone,
    is_member: user.is_member,
    avatar: user.avatar,
    member: user.member,
    realname: user.realname
  }
  const encryptedUser = encrypt(JSON.stringify(userData));
  const domain = process.env.VUE_APP_COOKIE_DOMAIN;
  const cookieStr = `hczyw_user=${encodeURIComponent(encryptedUser)}; domain=${domain}; path=/; SameSite=Lax`;
  document.cookie = cookieStr;
}

export function setToken(token) {
  const domain = process.env.VUE_APP_COOKIE_DOMAIN;
  document.cookie = `hczyw_token=${token}; domain=${domain}; path=/; SameSite=Lax`;
}

export function removeUserInfo() {
  const domain = process.env.VUE_APP_COOKIE_DOMAIN;
  document.cookie = `hczyw_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
}

export function removeToken() {
  const domain = process.env.VUE_APP_COOKIE_DOMAIN;
  document.cookie = `hczyw_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
}


export function isLoggedIn() {
  return !!getToken();
}
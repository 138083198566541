import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

export function encrypt(data) {
  if (data === undefined || data === null) {
    return '';
  }
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
}

export function decrypt(ciphertext) {
  if (!ciphertext) {
    return null;
  }
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}
import axios from 'axios'
import store from '@/store'
// import storage from 'store'
// import notification from 'ant-design-vue/es/notification'
import message from 'ant-design-vue/es/message'
import router from '@/router'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getToken, removeUserInfo, removeToken } from './auth';

// 创建 axios 实例
// const mes = window.location
// const _baseurl = `//${mes.hostname}:${mes.port}`
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASEURL,
  // baseURL: '/app',
  timeout: 10000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 500) {
      message.error(data.message)
    }
    if (error.response.status === 403) {
      message.error(data.message)
      // notification.error({
      //   message: '错误',
      //   description: data.message
      // })
    }
    if (error.response.status === 401) {
      // message.error(data.message)
      removeUserInfo();
      removeToken();
      store.commit("logout");
      router.replace({ name: "login" });
      message.error("登录已过期，请重新登录");
      // notification.error({
      //   message: '错误',
      //   description: data.message
      // })
      // if (token) {
      //   store.dispatch('Logout').then(() => {
      //     setTimeout(() => {
      //       window.location.reload()
      //     }, 1500)
      //   })
      // }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = getToken()
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers[ACCESS_TOKEN] = 'Bearer ' + token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
